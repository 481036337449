<template>
	<div class="view">
		<div class="top-media">
			<div class="back" ref="back">
				<el-button class="back-btn" @click="$router.go(-1)">返回</el-button>
			</div>
			<div class="media-info">
				<div class="flex-align-between">
					<div class="info-title" @mouseenter="toggleEditBtn" @mouseleave="toggleEditBtn">
						<span class="content name-content" v-if="!titleEditing">{{ titleValue || "昵称" }}</span>
						<button class="user-edit-btn csp" v-if="titleEditBtn && !titleEditing" @click="startEdit">修改</button>
						<div class="user-edit" v-if="titleEditing">
							<el-input class="edit-input" v-model="titleValue" placeholder="请填写机位名称" maxlength="32" show-word-limit> </el-input>
							<el-button class="edit-submit-btn" type="primary" @click="submitEdit">提交</el-button>
							<el-button class="edit-cancel-btn" @click="cancelEdit">取消</el-button>
						</div>
					</div>
					<el-button class="refresh-btn" icon="el-icon-refresh-right" @click="updateView">刷新</el-button>
				</div>
				<div style="margin-top: 23px">
					<div class="info-likes"><img src="@/assets/images/camera/like.svg" alt="" />{{ cameraInfo.subscribers }}</div>
					<div class="info-location"><img src="@/assets/images/camera/location.svg" alt="" />{{ cameraInfo.location }}</div>
					<div class="info-user"><img src="@/assets/images/camera/user.svg" alt="" />@{{ userName }}</div>
				</div>
			</div>
			<div class="media-video" v-loading="mediaLoading">
				<div class="media-video-wrap">
					<!-- <img src="@/assets/images/camera/video.png" alt="" class="frame" /> -->
					<img :src="frameUrl" alt="" class="frame" />
					<div class="video-marks flex-column-between" v-if="!mediaLoading">
						<div class="marks-top flex-between">
							<div class="top-left flex-align">
								<img src="@/assets/images/camera/logo.svg" alt="" class="video-logo" v-if="logoMark" />
								<div class="video-time" v-if="timeMark">
									{{
										JSON.stringify(viewPortsData) !== "[]" &&
										dayjs(viewPortsData.timestamps[currentFrame] * 1000)
											.utc()
											.local()
											.format("YYYY-MM-DD THH:mm:ssZ")
									}}
								</div>
								<div class="video-user" v-if="userMark">@{{ userName }}</div>
							</div>
							<div class="top-right video-good">
								<img src="@/assets/images/camera/good.svg" alt="" />
								<p>{{ cameraInfo.likes }}</p>
							</div>
						</div>
						<div class="marks-bottom flex-between">
							<div>
								<div class="video-comment-wrap" v-show="JSON.stringify(commentList) !== '[]'">
									<ul class="video-comments" ref="videoComments">
										<li v-for="item in extendedMessages" :key="item.id" class="flex-align-between comments-item">
											<span class="comment-text wes">{{ item.commenter }}：{{ item.message_content }}</span>
											<span class="comment-time">{{ dayjs(item.message_time * 1000).format("YYYY-MM-DD HH:mm:ss") }}</span>
										</li>
									</ul>
								</div>
							</div>
							<div class="video-wetaher" v-if="weatherMark">
								<div class="wether-item">
									<img src="@/assets/images/camera/weather.svg" alt="" />
									<span>天气类型：{{ JSON.stringify(viewPortsData) !== "[]" && viewPortsData.weather[currentFrame] }}</span>
								</div>
								<div class="wether-item" v-if="JSON.stringify(viewPortsData) !== '[]' && viewPortsData.visibility">
									<!-- -->
									<img src="@/assets/images/camera/views.svg" alt="" class="weather-views" />
									<span class="weather-text">能见度：{{ viewPortsData.visibility[currentFrame] }}</span>
								</div>
								<p class="tips">以上天气信息基于AI图像识别</p>
							</div>
						</div>
					</div>
				</div>
				<progressBar @updateFrame="updateCurrentFrame" :progress="progress" @isPlay="playFrame" :isPlaying="isPlaying"></progressBar>
			</div>
		</div>
		<div class="bottom-setting">
			<div class="nav-bar">
				<div class="nav-item-container" v-for="(item, index) in items" :key="index" @click="selectItem(index)">
					<div class="nav-item">{{ item }}</div>
				</div>
				<div class="underline" :style="{ transform: `translateX(${currentIndex * 100}%)` }"></div>
			</div>
			<div class="content">
				<component
					:is="currentComponent"
					@changeCheck="changeMarks"
					@timePicke="changeTime"
					:controlTime="controllerTime"
					:isPlay="isPlaying"
					:refresh="isRefresh"
					:frame="frameUrl"></component>
			</div>
		</div>
	</div>
</template>

<script>
import controller from "./components/controller.vue";
import comment from "./components/comment.vue";
import weather from "./components/weather.vue";
import progressBar from "./components/progress.vue";
import { mapState } from "vuex";
export default {
	components: {
		controller,
		comment,
		weather,
		progressBar,
	},
	data() {
		return {
			scrollPosition: 0,
			scrollInterval: null,
			items: ["控制台", "留言管理", "天气识别"],
			currentIndex: 0, // 当前组件对应index
			titleValue: "", // 视角名
			titleEditing: false, // 是否正在修改视角名
			titleEditBtn: false, // 是否显示修改视角按钮
			cameraInfo: {}, // 机位基本信息
			commentList: [
				// 评论留言数据
				{
					id: 1,
					commenter: "朝阳小张",
					message_content: "good good study,day day up",
					message_time: "2024-11-08 18:25:11",
				},
				{
					id: 2,
					commenter: "小李",
					message_content: "good good study,day day up",
					message_time: "2024-11-08 18:25:11",
				},
				{
					id: 3,
					commenter: "小王",
					message_content: "夕阳无限好，只是近黄昏",
					message_time: "2024-11-08 18:25:11",
				},
			],
			logoMark: true, // 观天者水印
			weatherMark: true, // 天气类型
			userMark: true, // 用户名水印
			timeMark: true, // 时间信息
			controllerTime: {}, // 控制台的时间数据
			extendedMessages: [],
			mediaLoading: true,
			// 机位图片数据
			viewPortsData: [], // 视角数据
			// currentView: null, // 当前视角
			// currentViewIndex: 0, // 当前视角index
			currentFrame: 0, // 当前帧
			totalFrames: 0, // 总帧数
			frameUrl: "", // 帧地址
			isPlaying: false, // 序列帧播放状态 true 播放中 false 暂停
			progress: 0, // 进度条进度 %
			isRefresh: false,
		};
	},
	computed: {
		currentComponent() {
			switch (this.items[this.currentIndex]) {
				case "控制台":
					return "controller";
				case "留言管理":
					return "comment";
				case "天气识别":
					return "weather";
				default:
					return "controller";
			}
		},
		...mapState({
			userName: (state) => state.user.miniInfo.name,
		}),
	},
	methods: {
		// 对应组件
		selectItem(index) {
			this.currentIndex = index;
		},
		// 显示机位名称修改按钮
		toggleEditBtn() {
			this.titleEditBtn = !this.titleEditBtn;
		},
		// 修改机位名称
		startEdit() {
			this.titleEditing = true;
		},
		// 提交机位名称修改
		submitEdit() {
			let params = {
				camera_id: this.$route.params.id,
				camera_name: this.titleValue,
			};
			Api.Cameras.updateCameraName(params)
				.then((res) => {
					console.log(res);
					if (res.status == 204) {
						this.$message.success("机位名称修改成功");
						this.titleEditing = false;
					}
				})
				.catch((error) => {
					this.$message.error("修改失败");
					console.log("error", error);
				});
		},
		// 取消机位名称修改
		cancelEdit() {
			this.titleEditing = false;
		},
		startScrolling() {
			let content = this.$refs.videoComments;
			if (!content.querySelector("li")) {
				return;
			}
			let itemHeight = content.querySelector("li").clientHeight;
			this.scrollInterval = setInterval(() => {
				// }
				if (!this.isScrolling) {
					this.isScrolling = true;
					content.style.transform = `translateY(-${itemHeight}px)`;
					content.style.transition = "transform 1s linear";
					setTimeout(() => {
						// 将第一项移动到列表末尾并重置位置
						content.appendChild(content.firstElementChild);
						content.style.transition = "none";
						content.style.transform = "translateY(0)";
						this.isScrolling = false;
					}, 1000); // 应与CSS过渡时间相匹配
				}
			}, 50); // 每隔2秒滚动一次
		},
		stopScroll() {
			clearInterval(this.scrollInterval);
		},
		// 刷新 最新机位信息
		updateView() {
			this.isRefresh = true;
			this.mediaLoading = true;
		},
		// 获取当前机位基本信息
		getCamerasInfo() {
			let params = {
				camera_id: this.$route.params.id,
			};
			Api.Cameras.getCamerasInfo(params).then((res) => {
				// console.log("机位基本信息", res);
				this.cameraInfo = res.data;
				this.titleValue = res.data.camera_name;
			});
		},
		// 预加载图片
		preloadImg(data) {
			let images = new Array();
			data.map((item, i) => {
				images[i] = new Image();
				images[i].src = item;
			});
		},
		// 获取机位图片信息
		getCameraViewports() {
			this.mediaLoading = true;
			Api.Cameras.getCameraViewports(this.controllerTime)
				.then((res) => {
					// console.log("视角数据", res.data);
					this.mediaLoading = false;
					if (res.status == 200) {
						this.viewPortsData = res.data[0];
						this.totalFrames = this.viewPortsData.urls.length;
						this.frameUrl = this.viewPortsData.urls[this.currentFrame];
						this.updateCurrentFrame(0);
						this.preloadImg(this.viewPortsData.urls);
					}
				})
				.catch((error) => {
					this.mediaLoading = false;
					console.log(error);
				});
		},
		// 设置进度条
		setProgress(progress) {
			this.$nextTick(() => {
				this.progress = progress;
			});
		},
		// 更新当前帧
		updateCurrentFrame(progress) {
			this.setProgress(progress);
			this.currentFrame = Math.floor((progress / 100) * (this.totalFrames - 1));
			this.frameUrl = this.viewPortsData.urls[this.currentFrame];
		},
		// 播放状态
		playFrame(data) {
			// console.log("是否在播放", data);
			if (data) {
				this.startViewport(this.currentFrame);
				this.startScrolling();
			} else {
				this.startViewport(this.currentFrame, true);
				this.stopScroll();
			}
		},

		// 序列帧方法
		// @param {Number} startNum 开始播放的帧数
		// @param {Boolean} pause 是否暂停
		startViewport(startNum, pause) {
			const frameList = this.viewPortsData.urls; // 帧列表
			const delay = 100; // 帧间隔
			this.isPlaying = !pause; // 播放状态  true 播放 false 暂停  //播放状态与暂停状态相反
			const animation = (i) => {
				// 结束
				if (i === this.totalFrames) {
					i = 0;
					this.setProgress(0);
					this.frameUrl = frameList[0];
					this.currentFrame = 0;
				}
				// 暂停
				if (!this.isPlaying) {
					return;
				}
				// 播放
				this.frameUrl = frameList[i];
				this.currentFrame = i;
				setTimeout(() => {
					if (!this.isPlaying) {
						return;
					}
					animation(i + 1);
					let calcProgress = ((i + 1) / (this.totalFrames - 1)) * 100;
					this.progress = Math.max(0, Math.min(100, calcProgress));
				}, delay);
				// };
			};
			animation(startNum);
			return;
		},

		// 改变水印
		changeMarks(data) {
			// console.log("check", data);
			let map = {
				观天者水印: "logoMark",
				天气类型: "weatherMark",
				用户名水印: "userMark",
				时间信息: "timeMark",
			};
			let list = ["观天者水印", "天气类型", "用户名水印", "时间信息"];
			let different = list.filter((item) => !data.includes(item));
			// console.log("different", different);

			// 选中的类别
			data.map((item) => {
				let name = map[item];
				this[name] = true;
			});

			// 未选中的类别
			different.map((item) => {
				let name = map[item];
				this[name] = false;
			});
		},
		// 改变视频时间
		changeTime(data) {
			this.stopScroll();
			this.isPlaying = false;
			this.isRefresh = false;
			console.log("控制台时间", data);
			this.controllerTime = data;
			this.getCameraViewports();
			this.getMessageList();
		},
		// 获取机位留言列表
		getMessageList() {
			let params = {
				camera_id: this.$route.params.id,
				start_time: this.controllerTime.start_time,
				end_time: this.controllerTime.end_time,
				page: 1,
				size: 10000,
			};
			Api.Cameras.getMessageList(params).then((res) => {
				// console.log("全部留言", res);
				this.commentList = res.data.content;
				this.extendedMessages = [...this.commentList, ...this.commentList];
				// this.startScrolling();
			});
		},
	},
	mounted() {
		this.getCamerasInfo();
	},
};
</script>

<style lang="less" scoped>
.view {
	padding-right: 8px;
	width: 100%;
	height: 100%;
	overflow-y: auto;

	/*修改滚动条样式*/
	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		border-radius: 2px;
	}

	&::-webkit-scrollbar-button {
		height: 0px;
	}

	&::-webkit-scrollbar-thumb {
		background: #bfbfbf;
		border-radius: 4px;
	}

	.top-media {
		background-color: #fff;
		border-radius: 10px;
		margin-bottom: 13px;
		padding: 26px 73px 22px 30px;

		.back-btn,
		.refresh-btn {
			border-radius: 16px;
			border: 1px solid #01aded;
			padding: 0 16px;
			line-height: 24px;
			color: #01aded;

			&:hover {
				background-color: #01aded;
				color: #fff;
			}
		}

		.media-info {
			margin-top: 26px;
			margin-bottom: 23px;
			padding-left: 43px;

			.info-title {
				font-size: 16px;
				font-weight: 600;
				padding-right: 60px;

				.user-edit-btn {
					margin-left: 12px;
					color: #01aded;
					font-weight: 600;
					font-size: 14px;

					&:hover {
						color: rgba(1, 173, 237, 0.65);
					}

					.edit-input {
						width: 330px;
						position: relative;

						/deep/.el-input__inner {
							padding-right: 130px;
						}
					}
				}

				/deep/ .edit-input {
					width: 330px;

					.el-input__inner {
						border-radius: 10px;
					}

					.el-input__inner:focus {
						border: 1px solid #01aded;
					}

					.el-input .el-select__caret {
						font-size: 12px;
					}
				}

				.edit-submit-btn {
					margin-left: 16px;

					&.el-button--primary {
						border: 1px solid #01aded;
						background-color: #01aded;

						&:hover {
							background-color: #4ec5f1;

							border: 1px solid #4ec5f1;
						}
					}
				}

				.edit-cancel-btn {
					margin-left: 10px;
					border: 1px solid #9f9f9f;
					color: #9f9f9f;

					&:hover {
						border: 1px solid #9f9f9f;
						background-color: #f5f5f5;
					}
				}

				.edit-submit-btn,
				.edit-cancel-btn {
					border-radius: 4px;
					padding: 0 16px;
					line-height: 30px;
					font-weight: 600;
				}
			}

			.user-edit {
				display: inline-block;
			}

			.info-likes {
				display: inline-block;
				margin-right: 24px;
				border-radius: 4px;
				background: #efefef;
				padding: 1px 11px;
				line-height: 24px;

				& > img {
					margin-right: 6px;
				}
			}

			.info-location {
				display: inline-block;
				padding: 0 24px;
				border: 1px solid #e2e5ed;
				border-top: 0;
				border-bottom: 0;

				& > img {
					margin-right: 8px;
					vertical-align: -2px;
				}
			}

			.info-user {
				display: inline-block;
				padding: 0 24px;
				& > img {
					margin-right: 8px;
					vertical-align: -2px;
				}
			}
		}

		.media-video {
			padding-left: 43px;

			.media-video-wrap {
				width: 824px;
				height: 463px;
				position: relative;
			}

			.marks-top {
				align-items: flex-start;
			}

			.marks-bottom {
				align-items: end;
			}

			.frame {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.video-marks {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				box-sizing: border-box;
				padding: 20px 25px;

				.video-time,
				.video-user {
					margin-left: 18px;
					border-radius: 13px;
					padding: 0 15px;
					line-height: 24px;
					color: #fff;
					background: rgba(0, 0, 0, 0.4);
				}

				.video-good {
					border-radius: 4px;
					background: rgba(0, 0, 0, 0.4);
					color: #fff;
					padding: 8px 14px 6px;

					& > img {
						display: block;
						margin: 0 auto 2px;
					}
				}

				.video-comment-wrap {
					background: rgba(0, 0, 0, 0.4);
					height: 111px;
					overflow: hidden;
					white-space: nowrap;
					position: relative; /* 添加相对定位 */
					width: 430px;
					.video-comments {
						padding: 12px 25px 0px;
						color: #fff;
						box-sizing: border-box;
						transition: ease-in;
						.comments-item {
							margin-top: 2px;
							line-height: 24px;
							font-size: 12px;

							&:first-child {
								margin-top: 0;
							}
						}

						.comment-time {
							color: #c0c0c0;
							margin-left: 15px;
						}

						.comment-text {
							width: 236px;
						}
					}
				}

				.video-wetaher {
					color: #fff;
					background: rgba(0, 0, 0, 0.4);
					padding: 13px 38px 13px 25px;

					.wether-item > span {
						display: inline-block;
						font-size: 12px;
						font-style: normal;
						font-weight: 600;
						line-height: 24px;
						vertical-align: 1px;
						margin-left: 12px;
						margin-top: 4px;
					}

					.wether-item > .weather-text {
						margin-left: 10px;
					}

					.tips {
						margin-top: 8px;
						color: #c0c0c0;
					}
				}
			}
		}
	}

	.bottom-setting {
		border-radius: 10px;
		background-color: #ffff;
		overflow: hidden;
		.nav-bar {
			display: flex;
			align-items: center;
			padding-right: 40px;
			position: relative;
			border-bottom: 1px solid #e2e5ed;
		}

		.nav-item-container {
			position: relative;
			text-align: center;
			cursor: pointer;
			transition: color 0.3s;
			line-height: 48px;
			width: 60px;
			padding: 0 30px;
		}

		.nav-item-container:hover {
			color: #01aded;
		}

		.nav-item {
			position: relative;
		}

		.underline {
			position: absolute;
			bottom: 0px;
			left: 0;
			width: 120px; /* 调整宽度以适应导航项 */
			height: 3px;
			background-color: #01aded;
			transition: transform 0.3s ease-in-out;
		}
	}
}
</style>
