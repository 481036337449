<template>
	<div class="progres-wrapper">
		<div class="progress-btn" @click="startPlay()">
			<img :src="isPlay ? pauseIcon : playIcon" alt="" />
		</div>
		<div class="progress-bar csp" @mousedown="startDrag" @click="clickProgressBar">
			<div class="progress-fill" ref="fill" :style="{ width: `${progress}%` }">
				<div class="progress-handle csp" ref="handle" @mousedown="startDrag"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		// 进度条长度  max:100 min:0
		progress: {
			type: Number,
			default: 0,
		},
		// 是否在播放
		isPlaying: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			barWidth: 0, // .progress-bar宽度
			isDragging: false, // 是否正在拖动进度条
			startX: 0, // 鼠标按下时的x坐标
			startProgress: 0, // 进度条开始时的进度
			isPlay: false,
			playIcon: require("@/assets/images/camera/play.svg"),
			pauseIcon: require("@/assets/images/camera/pause.svg"),
		};
	},
	watch: {
		isPlaying(newVal) {
			if (!newVal) {
				this.isPlay = false;
			}
		},
	},
	methods: {
		// 开始播放
		startPlay() {
			this.isPlay = !this.isPlay;

			this.$emit("isPlay", this.isPlay);
		},
		// 进度条方法
		startDrag(event) {
			this.isDragging = true;
			this.startX = event.clientX;
			this.startProgress = this.progress;
			this.barWidth = this.$refs.fill.parentElement.offsetWidth;
			document.addEventListener("mousemove", this.drag);
			document.addEventListener("mouseup", this.stopDrag);
		},
		// 进度条滑动方法
		drag(event) {
			if (this.isDragging) {
				const deltaX = event.clientX - this.startX;
				const newProgress = (deltaX / this.barWidth) * 100 + this.startProgress;
				this.setProgress(newProgress);
			}
		},
		// 设置进度条长度
		setProgress(newProgress) {
			this.progress = Math.max(0, Math.min(100, newProgress));
			this.$emit("updateFrame", this.progress);
		},
		// 停止滑动
		stopDrag() {
			this.isDragging = false;
			document.removeEventListener("mousemove", this.drag);
			document.removeEventListener("mouseup", this.stopDrag);
		},
		// 点击进度条
		clickProgressBar(event) {
			if (!this.isDragging) {
				const rect = this.$refs.fill.parentElement.getBoundingClientRect();
				const clickX = event.clientX - rect.left;
				const newProgress = (clickX / this.barWidth) * 100;
				this.setProgress(newProgress);
			}
		}, 
	},
};
</script>

<style lang="less" scoped>
.progres-wrapper {
	margin-top: 5px;
	

	.progress-btn {
		display: inline-block;
		vertical-align: -5px;
		font-size: 0;
		// margin-right: 15px;
		padding: 20px 15px 20px 0;
	}
}
.progress-bar {
	display: inline-block;
	position: relative;
	margin: 0 8px;
	border-radius: 22px;
	height: 6px;
	width: calc(100% - 50px);
	background-color: #d9d9d9;
	user-select: none;

	.progress-fill {
		position: absolute;
		left: 0;
		top: 0;
		border-radius: 22px;
		height: 100%;
		background-color: #01aded;

		.progress-handle {
			position: absolute;
			top: 50%;
			left: 100%;
			border: 2px solid #fff;
			border-radius: 50%;
			width: 10px;
			height: 10px;
			background-color: #fff;
			transform: translate(-50%, -50%);
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
		}
	}

	&:hover {
		height: 6px;

		.progress-fill .progress-handle {
			width: 12px;
			height: 12px;
		}
	}
}
</style>
