var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "controller" },
    [
      _c("div", { staticClass: "toolbar flex-align" }, [
        _c("div", { staticClass: "left" }, [
          _c(
            "div",
            { staticClass: "time flex-align" },
            [
              _c(
                "span",
                {
                  staticClass: "tool-name",
                  staticStyle: { "flex-shrink": "0" },
                },
                [_vm._v("时间")]
              ),
              _c("el-date-picker", {
                staticClass: "date-picker",
                attrs: {
                  type: "datetimerange",
                  "range-separator": "-",
                  "start-placeholder": "开始时间",
                  "end-placeholder": "结束时间",
                  "prefix-icon": "el-icon-date",
                  "popper-class": "video-time-picker",
                  "value-format": "timestamp",
                },
                on: { change: _vm.changeDatePicker },
                model: {
                  value: _vm.timeValue,
                  callback: function ($$v) {
                    _vm.timeValue = $$v
                  },
                  expression: "timeValue",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "other-tool flex-align" }, [
            _c(
              "div",
              { staticClass: "other-item duration" },
              [
                _c("span", { staticClass: "tool-name" }, [_vm._v("时长")]),
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "请选择",
                      "popper-class": "type-select",
                      "popper-append-to-body": true,
                    },
                    on: { change: _vm.changeDuration },
                    model: {
                      value: _vm.durationValue,
                      callback: function ($$v) {
                        _vm.durationValue = $$v
                      },
                      expression: "durationValue",
                    },
                  },
                  _vm._l(_vm.durationList, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "other-item interval" },
              [
                _c("span", { staticClass: "tool-name" }, [_vm._v("间隔")]),
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "请选择",
                      "popper-class": "type-select",
                      "popper-append-to-body": true,
                    },
                    on: { change: _vm.changeInterval },
                    model: {
                      value: _vm.intervalValue,
                      callback: function ($$v) {
                        _vm.intervalValue = $$v
                      },
                      expression: "intervalValue",
                    },
                  },
                  _vm._l(_vm.intervalList, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "other-item resolution" },
              [
                _c("span", { staticClass: "tool-name" }, [_vm._v("分辨率")]),
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "请选择",
                      "popper-class": "type-select",
                      "popper-append-to-body": true,
                    },
                    on: { change: _vm.changeResolution },
                    model: {
                      value: _vm.resolutionValue,
                      callback: function ($$v) {
                        _vm.resolutionValue = $$v
                      },
                      expression: "resolutionValue",
                    },
                  },
                  _vm._l(_vm.resolutionList, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rigth" },
          [
            _c(
              "el-checkbox-group",
              {
                staticClass: "checkbox-group",
                on: { change: _vm.changeCheck },
                model: {
                  value: _vm.checkList,
                  callback: function ($$v) {
                    _vm.checkList = $$v
                  },
                  expression: "checkList",
                },
              },
              [
                _c("el-checkbox", { attrs: { label: "观天者水印" } }),
                _c("el-checkbox", { attrs: { label: "天气类型" } }),
                _c("el-checkbox", { attrs: { label: "用户名水印" } }),
                _c("el-checkbox", { attrs: { label: "时间信息" } }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "release flex-align" }, [
        _c("p", [
          _vm._v("生成视频消耗积分：" + _vm._s(_vm.point)),
          _c("button", { staticClass: "howToPoints csp" }, [
            _vm._v("如果获取积分?"),
          ]),
        ]),
        _c(
          "button",
          { staticClass: "export-video-btn csp", on: { click: _vm.openTips } },
          [_vm._v("视频生成")]
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "tipsDialog",
          attrs: {
            visible: _vm.dialogVisible,
            width: "450px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("生成视频将消耗积分：" + _vm._s(_vm.point))]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.checkPoint } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "tipsDialog",
          attrs: {
            visible: _vm.nextDialogVisible,
            width: "450px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.nextDialogVisible = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v("视频生成任务已提交，请稍后前往"),
            _c("button", { staticClass: "album" }, [_vm._v("影集")]),
            _vm._v("查看"),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("返回"),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }