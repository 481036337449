var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "progres-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "progress-btn",
        on: {
          click: function ($event) {
            return _vm.startPlay()
          },
        },
      },
      [
        _c("img", {
          attrs: { src: _vm.isPlay ? _vm.pauseIcon : _vm.playIcon, alt: "" },
        }),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "progress-bar csp",
        on: { mousedown: _vm.startDrag, click: _vm.clickProgressBar },
      },
      [
        _c(
          "div",
          {
            ref: "fill",
            staticClass: "progress-fill",
            style: { width: `${_vm.progress}%` },
          },
          [
            _c("div", {
              ref: "handle",
              staticClass: "progress-handle csp",
              on: { mousedown: _vm.startDrag },
            }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }